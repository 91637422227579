<template>
    <section class="apr-section deals-section">
        <div class="container px-4 xl:px-0 mx-auto">
            <h2 v-if="blok.Title" class="apr-section-title">
                {{ blok.Title }}
            </h2>
            <div v-if="blok.Subtitle" class="apr-section-subtitle mb-4">{{ blok.Subtitle }}</div>
            <AprCarousel
                v-if="blok.Layout === 'Slider'"
                :slider-items="blok.DealsItems"
                :slider-options="sliderOptions"
            >
                <template #default="{ item }">
                    <DealsCard :card="item" />
                </template>
            </AprCarousel>
            <div v-else class="flex flex-col md:grid grid-cols-2 lg:grid-cols-3 gap-5 justify-center items-stretch">
                <div v-for="deal in blok.DealsItems" :key="deal._uid">
                    <DealsCard :card="deal" />
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import type { IDealsSection } from '~/types/Deals';
import DealsCard from '~/components/cards/DealsCard.vue';
import AprCarousel from './AprCarousel.vue';

defineProps<{ blok: IDealsSection }>();

const sliderOptions = ref({
    slidesCount: {
        xl: 3,
        lg: 3,
        md: 2,
        sm: 1,
    },
});
</script>

<style lang="postcss" scoped>
.deals-section {
    :deep(.carousel) {
        @apply -mx-3;
        .carousel__slide {
            @apply pb-6;
        }
        .carousel__pagination {
            @apply mt-0;
        }
    }
}
</style>
